import React from "react"

import Layout from "components/Layout"
import PageHeader from "components/PageHeader"
import { Main } from "page_components/apartment"

const Apartment = ({ pageContext }) => {
  return (
    <Layout seo={{ title: pageContext?.data?.title }}>
      <section className="container-fluid">
        <PageHeader title={pageContext?.data?.title} />
      </section>
      <Main data={pageContext?.data?.data} />
      <div className="container-fluid">
        <PageHeader title="Zapraszamy do kontaktu" />
      </div>
    </Layout>
  )
}

export default Apartment
