import "./styles.scss"

import React, { useState } from "react"

import Button from "components/Button"

import IcoArrow from "assets/icons/ico-button-arrow-back.svg"

const Main = ({ data }) => {
  const [view, setView] = useState("2d")
  const pdf_name = `mazowiecka115-${data.slug}.pdf`

  return (
    <section className="apartment-main">
      <div className="container-fluid">
        <div className="apartment-main__back">
          <Button to="/mieszkania/" className="button--bordered button--narrow">
            <img src={IcoArrow} alt="" />
            powrót
          </Button>
        </div>

        <div className="row">
          <div className="col-lg-4">
            {/* {data?.acfApartment?.price && (
              <div className="apartment-main__list">
                <strong>Cena brutto</strong>
                <h2>
                  {data?.acfApartment?.regularPrice && (
                    <>
                      <h5>
                        {data?.acfApartment?.regularPrice.toLocaleString()}
                      </h5>
                      <br />
                    </>
                  )}
                  {data?.acfApartment?.price.toLocaleString()}
                  <span>PLN</span>
                </h2>
              </div>
            )}

            {data?.acfApartment?.priceForSquareMeter && (
              <div className="apartment-main__list">
                <strong>
                  Cena brutto /m<sup>2</sup>
                </strong>
                <h2>
                  {data?.acfApartment?.priceForSquareMeter.toLocaleString()}
                  <span>
                    PLN/m<sup>2</sup>
                  </span>
                </h2>
              </div>
            )} */}

            {data?.acfApartment?.area && (
              <div className="apartment-main__list">
                <strong>Metraż</strong>
                <h2>
                  {data?.acfApartment?.area}
                  <span>
                    m<sup>2</sup>
                  </span>
                </h2>
              </div>
            )}

            {data?.acfApartment?.roomsnumber && (
              <div className="apartment-main__list">
                <strong>Ilość pokoi</strong>
                <h2>{data?.acfApartment?.roomsnumber}</h2>
              </div>
            )}

            {data?.acfApartment?.floor && (
              <div className="apartment-main__list">
                <strong>Piętro</strong>
                <h2>{data?.acfApartment?.floor}</h2>
              </div>
            )}

            {data?.acfApartment?.location && (
              <div className="apartment-main__list">
                <strong>Ekspozycja</strong>
                <h2>{data?.acfApartment?.location}</h2>
              </div>
            )}

            {data?.acfApartment?.roomsList && (
              <div className="apartment-list__rooms">
                {data?.acfApartment?.roomsList?.map((item, index) => (
                  <div className="apartment-list__rooms-item" key={index}>
                    {index + 1}. {item.name}
                    <strong>
                      {item.area}m<sup>2</sup>
                    </strong>
                  </div>
                ))}
              </div>
            )}

            <div className="apartment-list__cta">
              <a
                className="button"
                href={require(`assets/pdfs/${pdf_name}`).default}
                download={pdf_name}
              >
                pobierz pdf
              </a>
            </div>
          </div>

          <div className="col-lg-7 offset-lg-1">
            <div className="apartment-main__nav">
              <div>
                <strong>Widok</strong>
                {data?.acfApartment?.visualization2d?.sourceUrl && (
                  <button
                    onClick={() => setView("2d")}
                    className={view === "2d" ? "current" : ""}
                  >
                    2D
                  </button>
                )}
                {data?.acfApartment?.visualization3d && (
                  <button
                    onClick={() => setView("3d")}
                    className={view === "3d" ? "current" : ""}
                  >
                    3D
                  </button>
                )}
                {/* {data?.acfApartment?.visualization360 && (
                  <button
                    onClick={() => setView("360")}
                    className={view === "360" ? "current" : ""}
                  >
                    360°
                  </button>
                )}
                {data?.acfApartment?.virtualWalk && (
                  <a
                    href={data?.acfApartment?.virtualWalk}
                    target="_blank"
                    rel="noreferrer"
                  >
                    wirtualny spacer
                  </a>
                )} */}
              </div>
              {view === "2d" && (
                <img
                  src={require("assets/icons/ico-compass.svg").default}
                  alt=""
                />
              )}
            </div>

            <div className="apartment-main__image">
              {view === "2d" && (
                <img
                  src={data?.acfApartment?.visualization2d?.sourceUrl}
                  alt=""
                />
              )}
              {view === "3d" && (
                <img src={data?.acfApartment?.visualization3d} alt="" />
              )}
              {view === "360" && (
                <iframe
                  title="Mazowiecka 115"
                  src={data?.acfApartment?.visualization360}
                  width="100%"
                  height="700px"
                ></iframe>
              )}
            </div>

            <div className="apartment-list__floor">
              {data?.acfApartment?.floorView && (
                <img
                  src={data?.acfApartment?.floorView.sourceUrl}
                  alt="{title}"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Main
